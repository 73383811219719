<template>
  <div />
</template>
<script>
export default {
  mounted() {
    this.openFileInNewTab(this.$route.params.id)
  },
  methods: {
    async openFileInNewTab(id) {
      const input = {
        type: 'user-manual',
        id,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/user-manual/get-temp-url',
        input,
      )

      if (response && response.status) {
        const newTab = window.open(response.data.temp_url, '_blank')
        if (newTab) {
          newTab.focus()
          this.$router.push({ name: 'mediaUpload' })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/component-css/help.scss";
</style>
